define("discourse/plugins/stemaway-project-generation/discourse/components/after-topic-progress/aivia-evaluator", ["exports", "@ember/component", "@ember/component/template-only", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "@ember/template-factory"], function (_exports, _component, _templateOnly, _isComponent, _thisFallbackHelper, _tryLookupHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{ai-mentor}}
  */
  {
    "id": "V/9IY6Cd",
    "block": "[[[41,[28,[32,0],[\"ai-mentor\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"ai-mentor\"],[[28,[32,1],[\"ai-mentor\"],null]]]]],[[[1,[52,[30,1,[\"ai-mentor\"]],[28,[30,1,[\"ai-mentor\"]],null,null],[28,[32,2],[[30,0],\"ai-mentor\",\"[\\\"The `ai-mentor` property path was used in the `discourse/plugins/stemaway-project-generation/discourse/components/after-topic-progress/aivia-evaluator.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.ai-mentor}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]]],[\"maybeHelpers\"],false,[\"if\",\"ai-mentor\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/stemaway-project-generation/discourse/components/after-topic-progress/aivia-evaluator.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "aivia-evaluator"));
});